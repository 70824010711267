/* eslint-disable max-len */
import React from 'react';
import ThankYouTemplate from '@cleverrealestate/clever-components-v2/dist/components/ThankYouTemplate';
import Layout from '../components/layout';
import SEO from '../components/Seo';

const ThankYouBuy = () => (
  <Layout
    colorTransitionStart={50}
    shadowTransitionStart={50}
    colorTransitionLength={100}
    shadowTransitionLength={100}
    hideContactUs
  >
    <SEO
      pathname="/thank-you-buy/"
      title="Thanks For Signing Up!"
      meta={[{
        name: 'robots',
        content: 'noindex',
      }]}
    />
    <ThankYouTemplate />
  </Layout>
);

export default ThankYouBuy;
